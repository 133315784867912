* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  /**
   * Verhindert, dass ein fokussiertes Element hinter dem Header oder Footer unsichtbar wird
   * • Höhe des StickyHeader-Elements (4rem) plus Platz (2rem) für ein Attribut-Label
   * • Höhe des Footer-Elements (6rem) plus Padding (0.5rem)
   */
  scroll-padding: 6rem 0 6.5rem 0;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
