.container {
  width: 100%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1336px) {
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
